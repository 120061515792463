<template>
  <div class="card">
    <div class="card-header border-0">
      <router-link :to="{ name: 'ThirdPartyList' }">Back</router-link>
      <h3 class="mb-0 text-left">Third Party Record <small>(User ID: {{ third_party_detail.user_id }} )</small></h3>

      <p><b>Name:</b> {{ third_party_detail.name }}, <b>Email:</b> {{ third_party_detail.user_email }}</p>
      <p><b>Level And XP Look Book:</b> <a
          href="https://aircorporateportal-my.sharepoint.com/:x:/r/personal/manas_murali_mywhoosh_com/Documents/MyWhoosh_Manas/OffcialDocs/Docs/Updated%20Docs/Editable%20Files%20Excel/Economy%20Spreadsheets/Level%20Up%20System%20Balance%20Sheet.xlsx?d=w21b2361011b14cfcaf617802ef92ce48&csf=1&web=1&e=5KX8SE">View
        Look Book</a>
        <a target="_blank" :href="profile_link">View Url</a>
      </p>
      <p><b> Profile Link:</b>
        <a target="_blank" :href="profile_link">View Url</a>
      </p>
      <p v-for="(file,key) in proof_file_url" :key="key">
        <b>Proof files</b> {{ key + 1 }}
        <a download :href="file">Download file</a>
      </p>
      <p>
        <b>Source: </b>{{ migration_platform }}
      </p>
      <p>
        <b>Status: </b>{{ status }}
      </p>
      <div class="col-3" style="    margin-left: -1%; display:none">
        <div class="form-group">
          <label class="form-control-label">User Level <small style="font-size: 14px;">(Suggested Level:
            {{ suggested_level }})</small></label>
          <input type="text" class="form-control" v-model="level">

        </div>
      </div>
      <div class="col-3" style="    margin-left: -1%;">
        <div class="form-group">
          <label class="form-control-label">Player XP <small style="font-size: 14px;">(Suggested XP:
            {{ suggested_player_xp }})</small></label>
          <input type="text" class="form-control" v-model="player_xp">

        </div>
      </div>
      <div class="col-9" style="    margin-left: -1%;">
        <div class="form-group">
          <label class="form-control-label">Reason For Rejection </label>
          <input type="text" class="form-control" v-model="reason">

        </div>
      </div>

      <p>
        <button type="button" ref="approve_request_btn" v-if="status === 'submitted'" @click="approveRequest"
                class="btn btn-danger">Give Approval
        </button>
        <button type="button" ref="approve_request_btn" v-if="status === 'submitted'" @click="rejectRequest"
                class="btn btn-danger">Reject Approval
        </button>
      </p>


    </div>


    <div class="card-body">
      <h2>Update Section</h2>
      <label class="form-control-label d-block">Total time spent</label>
      <form @submit.prevent="submitHandler">
        <div class="row">
          <div class="col-2 col-sm-4 col-md-3">
            <div class="row">
              <div class="col-4">
                <label class="form-control-label d-block">Days</label>
                <input type="text" placeholder="Days" v-model="total_time_spent.days" class="form-control">
              </div>
              <div class="col-4">
                <label class="form-control-label d-block">Hours</label>
                <input type="text" placeholder="Days" class="form-control" v-model="total_time_spent.hours">
              </div>
              <div class="col-4">
                <label class="form-control-label d-block">Minutes</label>
                <input type="text" placeholder="Days" class="form-control" v-model="total_time_spent.minutes">
              </div>
            </div>
          </div>
          <div class="col-2 col-sm-4 col-md-3">
            <div class="form-group"><label class="form-control-label">Overall distance</label><input type="text"
                                                                                                     v-model="overall_distance"
                                                                                                     placeholder="Value"
                                                                                                     class="form-control">
            </div>
          </div>
          <div class="col-2 col-sm-4 col-md-3">
            <div class="form-group"><label class="form-control-label">Overall Elevation</label><input
                v-model="overall_elevation_meter" type="text" class="form-control"></div>
          </div>

          <div class="col-2 col-sm-4 col-md-4">
            <div class="form-group">
              <button v-if="status === 'submitted'" type="submit" class="btn btn-danger">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";


export default {
  name: "ThirdPartyDetail",


  data() {
    return {
      id: this.$route.params.id,
      migration_platform: "",
      total_time_spent: {
        days: "",
        hours: "",
        minutes: ""
      },
      overall_distance: "",
      overall_elevation_meter: "",
      reason: "",
      status: "",
      player_xp: "",
      suggested_player_xp: "",
      profile_link: "",
      proof_file_url: "",
      suggested_level: "",
      level: "",
      formdata: {}

    }
  },

  computed: {
    ...mapGetters('player', ['third_party_detail']),
  },

  methods: {
    async submitHandler() {
      this.formdata['migration_platform'] = this.migration_platform
      this.formdata['total_time_spent'] = this.total_time_spent
      this.formdata['overall_distance'] = this.overall_distance
      this.formdata['overall_elevation_meter'] = this.overall_elevation_meter
      this.formdata['status'] = this.status
      this.formdata['record_id'] = this.id

      await this.$store.dispatch('player/updateThirdPartyRecord', this.formdata).then(() => {
        this.getDetail()
      });

    },
    async getDetail() {
      await this.$store.dispatch('player/get_third_party_detail', {id: this.id})

      this.migration_platform = this.third_party_detail.migration_platform
      this.level = this.third_party_detail.level
      this.suggested_level = this.third_party_detail.suggested_level
      this.total_time_spent = this.third_party_detail.total_ride_time
      this.overall_distance = this.third_party_detail.total_distance
      this.overall_elevation_meter = this.third_party_detail.total_elevation
      this.status = this.third_party_detail.status
      this.player_xp = this.third_party_detail.player_xp
      this.suggested_player_xp = this.third_party_detail.suggested_player_xp
      this.profile_link = this.third_party_detail.profile_link
      this.proof_file_url = this.third_party_detail.proof_file_url
    },
    approveRequest() {
      this.$store.dispatch('player/postApprovedRequest', {id: this.id, level: this.level, player_xp: this.player_xp})
          .then(() => {
            window.location.reload()
          })
    },
    rejectRequest() {
      if (!this.reason) {
        alert("Please fill reason for rejection field")
        return false
      }

      this.$store.dispatch('player/postRejectRequest', {id: this.id, reason: this.reason})
          .then(() => {
            window.location.reload()
          })
    }
  },

  async mounted() {
    await this.getDetail()
  }


}
</script>
